<template>
  <div ref="detailElement">
    <CCard class="bg-light">
      <CCardBody>
        <div class="event-detail-container">
          <div class="left-content">
            <div class="event-image-container">
              <img
                v-if="img_id===null"
                class="w-100 rounded"
                src="/img/loading3.png"
              />
              <img
                v-else-if="snapshots.length && crop_img_id===null"
                :class="{'w-100':true, 'rounded':true, 'hovered':isHovered}"
                :src="snapshots[img_id].signed_url"
                :alt="alarm.event_name"
                @mouseover="enlargeImage"
                @mouseout="shrinkImage"
                @click.stop="onViewFullscreen"
              />
              <img
                v-else-if="snapshots.length && crop_img_id!==null"
                class="w-100 rounded"
                :src="object_cropped[crop_img_id].img_shade"
                @click.stop="crop_img_id=null;enlargeImage();"
              />
              <img v-else class="w-100 rounded" :src="snapshot_bak" />
              <div class="d-flex w-100 mt-2">
                <div class="object-crop-img-container mx-1" v-for="(crop,index) in object_cropped" :key="`object-crop-image-${index}`" @click.stop="onClickCrop(index)">
                  <img
                    :src="crop.crop_img"
                    :alt="`${alarm.event_name}-${alarm.ts}`"
                    :class="{'crop-img':true,'selected':crop_img_id===index}"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="right-content">
            <div class="event-info">
              <div class="event-info-item">
                <small class="text-dark">{{$t('cloud.title.device')}}</small>
                <p class="h6">{{alarm.bridge_name}}<span v-if="alarm.ch" class="mx-1"><CBadge color="dark">{{`CH ${alarm.ch}`}}</CBadge></span></p>
                <small class="text-dark">{{$t('cloud.title.branch')}}</small>
                <p class="h6">{{alarm.branch_name}}</p>
              </div>
              <div class="event-info-item">
                <small class="text-dark">{{$t('cloud.title.event_name')}}</small>
                <p class="h6"><span v-if="alarm.src_event_name">{{alarm.src_event_name}}</span></p>
                <small class="text-dark">{{$t('cloud.title.event_type')}}</small>
                <p class="h6">{{$t(`cloud.title.eventtype_${alarm.event_type}`)}}</p>
                <small class="text-dark">{{$t('cloud.title.log_at')}}</small>
                <p class="h6">{{alarm.fmt_time}}</p>
              </div>
              <div class="event-info-item">
                <small class="text-dark">{{$t('cloud.title.event_meta')}}</small>
                <p class="h6">{{metadata}}</p>
                <small class="text-dark">{{$t('cloud.title.color_label')}}</small>
                <div v-if="alarm.color_label">
                  <CIcon name="label" :class="`text-${$resource.COLOR_LABELS[alarm.color_label]}`" />
                </div>
                <div v-else>{{$t('cloud.title.none')}}</div>
              </div>
              <div class="event-info-item">
                <small class="text-dark">{{$t('cloud.title.action')}}</small>
                <p class="h6">{{actionList}}</p>
                <small class="text-dark">{{$t('cloud.title.rule_name')}}</small>
                <p class="h6"><span v-if="alarm.event_name">{{alarm.event_name}}</span></p>
              </div>
              <div class="event-info-item" v-if="alarm.metadata.event.generic_event_type">
                <small class="text-dark">범용 이벤트 유형</small>
                <p class="h6">{{ alarm.metadata.event.generic_event_type }}</p>
                <small class="text-dark">범용 이벤트 이름</small>
                <p class="h6">{{ alarm.metadata.event.generic_event_name }}</p>
                <small class="text-dark">범용 이벤트 설명</small>
                <p class="h6">{{ alarm.metadata.event.generic_event_description }}</p>
                <small class="text-dark">범용 이벤트 메타 정보</small>
                <p class="h6">{{ JSON.stringify(alarm.metadata.event.generic_event_metadata) }}</p>
              </div>
            </div>
          </div>
        </div>
      </CCardBody>
    </CCard>
    <ImageModal :title="getTitle" ref="imageModal" />
  </div>
</template>

<script>
import axios from 'axios'
import { mapState } from "vuex"
import ImageModal from '@/components/modals/ImageModal.vue'

export default {
  components: {
    ImageModal
  },
  props: {
    alarm: {type: Object, required: true},
    observer: {type: IntersectionObserver, required: true},
  },
  computed: {
    ...mapState([
      'capability'
    ]),
    getTitle() {
      if (!this.alarm) return this.$t('cloud.title.alarm');
      const time_fmt = this.$utility.GetDateTimeStr("$yyyy-$mm-$dd $HH:$MM:$ss", new Date(this.alarm.ts));
      return `${this.alarm.src_event_name} - ${time_fmt}`;
    },
    actionList() {
      const rule = this.alarm.metadata.rule || this.alarm.metadata;
      if (!rule.actions || rule.actions.length <= 0) return this.$t('cloud.title.none');
      const type_list = rule.actions.map(el => this.$t(`cloud.title.actiontype_${el.type}`));
      return type_list.join(', ');
    },
    metadata() {
      const alarm = this.alarm;
      if (alarm.event_meta1 || alarm.event_meta2 || alarm.event_meta3) {
        return `${alarm.event_meta1 || ""} ${alarm.event_meta2 || ""} ${alarm.event_meta3 || ""}`;
      }
      return this.$t('cloud.title.none');
    }
  },
  data() {
    return {
      loading: true,
      title: this.$t('cloud.title.alarm'),
      snapshots: [],
      object_cropped: [],
      img_id: null,
      crop_img_id: null,
      snapshot_bak: '/img/loading3.png',
      currentImage: null,

      isHovered: false
    };
  },
  mounted() {
    this.$refs.detailElement.vueInstance = this;
    this.observer.observe(this.$refs.detailElement);
  },
  methods: {
    getSnapshots() {
      this.loading = true;
      this.img_id = 0;
      this.snapshots = [{signed_url:'/img/loading3.png'}];
      axios.get(`/api/alarm/${this.alarm.id}/snapshot/`)
        .then(response => {
          let self = this;
          this.snapshots = this.$utility.copy(response.data);
          this.snapshots = this.snapshots.map(el => {
            let d = new Date(el.ts);
            el.time_fmt = self.$utility.GetDateTimeStr("$yyyy-$mm-$dd $HH:$MM:$ss", d);
            return el;
          });
          if (this.snapshots.length) {
            let alarm_date = new Date(this.alarm.ts);
            this.img_id = 0;
            let diff_min = 999999999;
            for (const id in this.snapshots) {
              let snapshot = this.snapshots[id];
              let snapshot_date = new Date(snapshot.ts);
              let diff = Math.abs(alarm_date - snapshot_date);
              if (diff_min > diff) {
                this.img_id = Number(id);
                diff_min = diff;
              }
            }
            this.extractObjects();
          } else {
            this.snapshot_bak = '/img/no_img4.png';
          }
        })
        .finally(() => {
          this.loading = false;
        })
    },
    onViewFullscreen() {
      this.$refs.imageModal.open(this.alarm, this.snapshots);
    },
    enlargeImage() {
      this.isHovered = true;
    },
    shrinkImage() {
      this.isHovered = false;
    },
    async extractObjects() {
      const object_list = this.alarm.metadata.event.object_list;
      if (!object_list || object_list.length <= 0) return;
      const snapshot = this.snapshots[this.img_id];
      for (let i=0;i<Math.min(10,object_list.length);i++) {
        const object = object_list[i];
        const x1 = object.bbox.x0;
        const y1 = object.bbox.y0;
        const x2 = object.bbox.x1;
        const y2 = object.bbox.y1;
        const crop_img = await this.cropImage(snapshot.signed_url, x1, y1, x2, y2);
        const img_shade = await this.applyAlphaShade(snapshot.signed_url, x1, y1, x2, y2);
        this.object_cropped.push({img_shade, crop_img, x1, y1, x2, y2});
      }
    },
    cropImage(dataURI, x1, y1, x2, y2) {
      return new Promise((resolve, reject) => {
        // 이미지 객체 생성
        let img = new Image();
        img.crossOrigin = "anonymous";
        img.onload = function() {
          // 상대 좌표를 절대 좌표로 변환
          let absX1 = x1 * img.width;
          let absY1 = y1 * img.height;
          let absX2 = x2 * img.width;
          let absY2 = y2 * img.height;

          // Canvas 요소 생성 및 설정
          let canvas = document.createElement('canvas');
          let ctx = canvas.getContext('2d');
          canvas.width = absX2 - absX1;
          canvas.height = absY2 - absY1;

          // 이미지를 캔버스에 그리기
          ctx.drawImage(img, absX1, absY1, canvas.width, canvas.height, 0, 0, canvas.width, canvas.height);

          // 새로운 Data URI 생성
          resolve(canvas.toDataURL('image/jpeg'));
        };
        img.onerror = reject;

        // 이미지 로드
        img.src = dataURI;
      });
    },
    applyAlphaShade(dataURI, x1, y1, x2, y2) {
      return new Promise((resolve, reject) => {
        // 이미지 객체 생성
        let img = new Image();
        img.crossOrigin = "anonymous"; // CORS 정책 준수

        img.onload = function() {
          // Canvas 설정
          let canvas = document.createElement('canvas');
          let ctx = canvas.getContext('2d');
          canvas.width = img.width;
          canvas.height = img.height;

          // 이미지를 캔버스에 그리기
          ctx.drawImage(img, 0, 0, img.width, img.height);

          // 상대 좌표를 절대 좌표로 변환
          let absX1 = x1 * img.width;
          let absY1 = y1 * img.height;
          let absX2 = x2 * img.width;
          let absY2 = y2 * img.height;

          // 전체 이미지에 음영 적용
          ctx.fillStyle = 'rgba(0, 0, 0, 0.8)'; // 반투명 검정색
          if (absY1 > 0) {
            ctx.fillRect(0, 0, img.width, absY1);
          }
          // 하단 영역 채우기
          if (absY2 < img.height) {
            ctx.fillRect(0, absY2, img.width, img.height - absY2);
          }
          // 왼쪽 영역 채우기
          if (absX1 > 0) {
            ctx.fillRect(0, absY1, absX1, absY2 - absY1);
          }
          // 오른쪽 영역 채우기
          if (absX2 < img.width) {
            ctx.fillRect(absX2, absY1, img.width - absX2, absY2 - absY1);
          }

          // 새로운 Data URI 생성
          resolve(canvas.toDataURL('image/png'));
        };
        img.onerror = reject;

        // 이미지 로드
        img.src = dataURI;
      });
    },
    onClickCrop(index) {
      if (this.crop_img_id === index) {
        this.crop_img_id = null;
      } else {
        this.crop_img_id = index;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@coreui/coreui/scss/coreui";

.event-detail-container {
  display: flex;
  flex-wrap: wrap;
}

.left-content {
  flex: 1 0 50%;
}

.right-content {
  flex: 1 0 30%;
  padding-left: 20px;
  border-radius: 10px;
}

.event-image-container {
  margin-bottom: 10px;
}

.event-image-container img {
  max-width: 100%;
  width: auto;
  height: auto;
  border: 5px solid transparent;
  transition: transform 0.3s ease-in-out;
}

.event-image-container img.hovered {
  /* transform: scale(1.015); */
  border: 5px solid $info;
  cursor: pointer;
}

.event-info {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.event-info-item {
  padding: 20px;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.object-crop-img-container {
  cursor: pointer;
  background-color: $gray-300;
  max-width: 60px;
  max-height: 75px;
  .crop-img {
    width: 60px !important;
    height: 75px !important;
    border: 3px solid transparent;
    object-fit: fill;
  }
  .crop-img.selected {
    border: 3px solid $danger;
  }
}

/* 기존 스타일에 추가 */
@media (max-width: 768px) {
  .event-detail-container {
    flex-direction: column;
  }

  .left-content, .right-content {
    flex: 0 0 100%;
  }

  .right-content {
    padding-left: 0;
  }

  .event-info {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  }
  .object-crop-img-container {
    cursor: pointer;
    background-color: $gray-300;
    max-width: 40px;
    max-height: 50px;
    .crop-img {
      width: 40px !important;
      height: 50px !important;
      border: 2px solid transparent;
      object-fit: fill;
    }
    .crop-img.selected {
      border: 2px solid $danger;
    }
  }
}
</style>