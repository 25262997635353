<template>
  <el-dialog
    top="2vh"
    :visible.sync="show"
    fullscreen
    :show-close="false"
    lock-scroll
    :close-on-click-modal="false"
  >
    <div v-loading.lock="loading">
      <div class="event-detail-container">
        <div class="event-image-container" :style="{ height: `calc(100vh - 280px)` }">
          <img
            class="event-image"
            v-if="snapshots.length"
            :src="snapshots[img_id].signed_url"
            @click.stop="onClose"
          />
        </div>
      </div>
      <div class="thumbnail-container">
        <div
          class="thumbnail"
          v-for="(image, index) in snapshots"
          :key="index"
          :class="{ 'selected': index === img_id, 'event-thumbnail': index === event_id }"
          @mouseover="setCurrentImage(index)"
          @click.stop="setCurrentImage(index)"
        >
          <img :src="image.signed_url" />
        </div>
      </div>
    </div>
    <template #title>
      <div class="d-flex justify-content-between align-items-center border-bottom">
        <div class="mb-3 px-3">
          <h5>{{title}}</h5>
        </div>
        <div>
          <el-button
            class="p-0 px-3 text-dark"
            type="text"
            icon="el-icon-close"
            @click="onClose"
          />
        </div>
      </div>
    </template>
    <template #footer>
      <div class="d-flex justify-content-end border-top">
        <CButton color="secondary" class="mt-2" @click="onClose">{{$t('button.close')}}</CButton>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import axios from 'axios'

export default {
  name: 'ImageModal',

  props: {
    title: {type: String, default: 'Alarm'}
  },

  data() {
    return {
      loading: false,
      show: false,
      alarm: null,
      img_id: 0,
      event_id: 0,
      snapshots: [{signed_url:'/img/loading2.png'}],
    }
  },

  methods: {
    async open(alarm, snapshots) {
      this.show = true;
      this.loading = true;
      this.alarm = this.$utility.copy(alarm);
      if (snapshots) {
        this.snapshots = this.$utility.copy(snapshots);
        const alarm_ts = new Date(this.alarm.ts).getTime();
        let images = this.snapshots.map(el => {return Math.abs((new Date(el.ts)).getTime() - alarm_ts);});
        this.img_id = this.$utility.findMinIndex(images);
      }
      await this.getSnapshots();
      this.loading = false;
    },
    onClose() {
      this.looading = false;
      this.show = false;
      this.alarm = null;
      this.img_id = 0;
      for (const s of this.snapshots) {
        try {
          URL.revokeObjectURL(s.signed_url);
        } catch (error) {
          continue;
        }
      }
      this.snapshots = [{signed_url:'/img/loading2.png'}];
    },
    async getSnapshots() {
      this.img_id = 0;
      let snapshots_res = await axios.get(`/api/alarm/${this.alarm.id}/snapshots/`);
      if (snapshots_res.status !== 200) {
        console.error(snapshots_res);
        return;
      }
      let snapshots_list = snapshots_res.data;
      let local_snapshots = [];
      for (const snapshots of snapshots_list) {
        const signed_url = snapshots.signed_url;
        const file_lengths = snapshots.file_lengths;
        const timestamps = snapshots.timestamps;
        const bridge_name = this.alarm.bridge_name;
        const ch = this.alarm.ch;
        // console.log(signed_url,file_lengths,timestamps,bridge_name,ch);
        let mass_bin_res = await axios.get(signed_url, { responseType: 'arraybuffer', withCredentials:false });
        if (mass_bin_res.status !== 200) {
          console.error(mass_bin_res);
          continue;
        }
        // 전체 파일 데이터
        const data = mass_bin_res.data;
        let offset = 0;
        local_snapshots = local_snapshots.concat(file_lengths.map(size => {
          const chunk = data.slice(offset, offset + size);
          offset += size;

          // Blob으로 변환
          const blob = new Blob([chunk]);
          // Blob을 Data URI로 변환
          return {
            'signed_url': URL.createObjectURL(blob),
            'ts': timestamps.shift(),
            'bridge_name': bridge_name,
            'ch': ch
          };
        }));
      }
      if (local_snapshots.length) {
        this.snapshots = local_snapshots;
        const alarm_ts = new Date(this.alarm.ts).getTime()*1000;
        let images = this.snapshots.map(el => {return Math.abs(el.ts - alarm_ts);});
        this.img_id = this.$utility.findMinIndex(images);
        this.event_id = this.img_id;
      } else {
        this.snapshot_bak = '/img/no_img4.png';
      }
    },
    setCurrentImage(id) {
      this.img_id = id;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@coreui/coreui/scss/coreui";

.event-detail-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.event-image-container {
  width: 100%;
  height: calc(100vh-280px) !important;
  margin-bottom: 10px;
  overflow: hidden;
  .event-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.thumbnail-container {
  display: flex;
  flex-wrap: wrap; /* Allow the thumbnails to wrap onto a new line if there isn't enough space */
  justify-content: center;
}

.thumbnail {
  margin: 0 5px;
  border: 2px solid transparent;
}

.thumbnail img {
  width: 40px;
  height: auto;
  cursor: pointer;
}

.thumbnail.selected {
  /* Apply any style you like here. For example: */
  border: 2px solid $danger;
}
.event-thumbnail {
  background-color: $primary;
  border: 2px solid $primary;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .thumbnail img {
    width: 40px;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .thumbnail img {
    width: 46px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .thumbnail img {
    width: 54px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .thumbnail img {
    width: 100px;
  }
}
</style>